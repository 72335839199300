.course-container {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    }

    .course-wrap {
        padding-bottom: 10px;
        font-size: 20px;
        font-weight: 900;
        width: 100%;
    }

    .course-Pane {
        width: 40%;
        text-align: center;
        padding:1rem;
        
    }

    .course-Pane > p {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    @media screen and (max-width: 820px) {
        .course-container {
            flex-direction: column;
            width: 100%;
        }

        .course-Pane {
            text-align: center;
            width: 100%;
        }

        .course-wrap {
            text-align: center;
            width: 100%;
        }
        

        

    }


    