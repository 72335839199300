.alex-container {
    background-color: #ffffff;
    padding: 4rem 0 4rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    }

    .alex-leftPane {
        width: 30%;
        text-align: center;
    }

    .alex-leftPane > img {
        width: 60%;
        border-radius: 50%;
        padding: 10px;
    }

    .alex-leftPane > h1, h2{
        font-family: 'Bebas Neue', cursive;
        font-size: 15px;
    }

    .alex-rightPane {
        width: 50%;
        padding: 2rem;
    }
    
    @media screen and (max-width: 820px) {
        .alex-container {
            flex-direction: column;
        }

        .alex-rightPane {
            text-align: start;
            width: 100%;
        }

        .alex-leftPane {
            width: 100%;
        }
    }


    .typewriter > span {
        color: #262626;
        overflow: hidden;   
        border-right: .10em solid #FF8D8D;
        white-space: nowrap;   
        margin: 0 auto;   
        letter-spacing: .4rem;   
        animation: typewriter 3s steps(30, end), cursor 1s step-end infinite;
      }

      @keyframes typewriter {
        from { 
          width: 0;
        }
        to { 
          width: 100%;
        }
      }
      
      @keyframes cursor {
        from, to { 
          border-color: transparent; 
      }
        50% { 
      border-color: #FF8D8D; 
        }
      }

      .alex-rightPane > p {
        background-color: rgb(51, 48, 48);
        font-size: 17px;
        color: white;
        padding: 3rem;
        text-align: center;
        border-radius: 20px;
        font-family: 'Prompt', sans-serif;
      }

      a {
          background-color: transparent;
          color: white;
          font-family: 'Prompt', sans-serif;
      }

  
