.project-container {
    background-color: #ffffff;
    padding: 4rem 0 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    }

    .project-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0%;
    }

    .project-leftPane {
        width: 20%;
        text-align: center;
    }

    .project-leftPane > img {
        width: 50%;
        padding: 10px;
        margin: 10px;
    }

    .project-rightPane {
        width: 70%;
        padding: 50px;
        text-align: flex-start;
        background-color: white;
        color: black;
        border-radius: 12px;
    }



    
    @media screen and (max-width: 820px) {
        .project-container {
            flex-direction: column;
        }

        .project-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0%;
        }

        .project-rightPane {
            width: 100%;
            text-align: center;
        }

        .project-leftPane {
            width: 100%;
        }

        .project-leftPane > img {
            width: 50%;
            padding: 10px;
            margin: 10px;
        }
    }